import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Outlet } from 'react-router';
import { Link, createSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { isUndefined, mapValues, range } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS, { getPath } from '@routes/paths';
import { Donor, Organization } from '@shared/types';
import { CountryList } from '@typedefs/org';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { Limits } from '@shared/limits';

import { donorSchemaResolver, DonorSchemaForm } from '@/schemas';
import { IconButtonAnimate } from '@components/animate';
import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';
import ConfirmDialog from '@components/ConfirmDialog';
import ConflictDialog from '@components/ConflictDialog';

import Form from './Form';
import AdditionalForm from './AdditionalForm';
import DonationList from './DonationList';
import PledgeList from './PledgeList';
import useRole from '@hooks/useRole';

// ----------------------------------------------------------------------
type Props = {
  context: 'donors' | 'donations' | 'issue' | 'reissue' | 'reissue-batch';
  readonly open?: boolean;
  readonly onClose?: (donor?: Donor.Donor) => void;
};
// ----------------------------------------------------------------------
export default function DonorDialog({ context = 'donors', open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const {
    updateDonor,
    createDonor,
    getTagsFromIds,
    getDonorById,
    getDonationReceipt,
    deleteDonor,
    isMemberNumberInUse,
    receipts,
  } = useDonation();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [skipEffect, setSkipEffect] = useState(false);
  const [isInConflict, setInConflict] = useState<string>();

  // --------------- effects ---------------
  const canEdit = hasAccess([
    Organization.Role.editor,
    Organization.Role.contributor,
    Organization.Role.donorOnly,
  ]);
  const canViewDonations = hasAccess([
    Organization.Role.editor,
    Organization.Role.contributor,
    Organization.Role.readOnly,
  ]);
  const donor = useMemo(() => getDonorById(params.donorId), [getDonorById, params.donorId]);
  const memberNumbers = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return {
      [currentYear - 1]: donor?.memberNumbers[currentYear - 1] || '',
      [currentYear]: donor?.memberNumbers[currentYear] || '',
      [currentYear + 1]: donor?.memberNumbers[currentYear + 1] || '',
    };
  }, [donor]);

  const hasReceipt = !!donor && !!receipts.find((r) => r.donorId === donor.id);

  useEffect(() => donor && analytics.donation.donorView(), [donor]);

  const defaultValues: DonorSchemaForm = useMemo(() => {
    let customFields = donor?.customFields || [];
    // add on to get to the desired limiting count
    customFields = customFields.concat(
      range(customFields.length, Limits.donorCustomFields).map((n) => '')
    );
    return {
      firstName: donor?.firstName || '',
      middleName: donor?.middleName || '',
      lastName: donor?.lastName || '',
      email: donor?.email || '',
      phone: donor?.phone || '',
      address: {
        address1: donor?.address?.address1 || '',
        address2: donor?.address?.address2 || '',
        city: donor?.address?.city || '',
        state: donor?.address?.state || '',
        postalCode: donor?.address?.postalCode || '',
        country:
          CountryList.find((c) => c.value === donor?.address?.country) ||
          CountryList.find((c) => c.value === org?.address.country) ||
          CountryList[0],
      },
      type: donor?.type || Donor.DonorType.individual,
      organization: donor?.organization || '',
      nonReceiptable: donor?.nonReceiptable || false,
      tags: getTagsFromIds(donor?.tagIds || []),
      memberNumbers: memberNumbers,
      notes: donor?.notes || '',
      customFields: customFields,
    };
  }, [getTagsFromIds, donor, memberNumbers, org?.address.country]);

  const methods = useForm<DonorSchemaForm>({
    resolver: donorSchemaResolver(memberNumbers, isMemberNumberInUse),
    defaultValues,
    // NOTE: this will break manual error setting!
    mode: 'all',
    criteriaMode: 'all',
  });

  // --------------- form ---------------
  const {
    watch,
    reset,
    setError,
    setValue,
    setFocus,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;
  const watchType = watch('type');
  const watchedNonReceiptable = watch('nonReceiptable');

  // --------------- effects ---------------
  useEffect(() => {
    if (skipEffect) {
      setSkipEffect(false);
      return;
    }

    if (isDirty && !isSubmitting) {
      const updateByUser = org?.users[donor?._meta.updatedBy as string]?.displayName;
      setInConflict(
        `${updateByUser} updated this donor. To avoid data loss, we need to reset this form.`
      );
    } else {
      reset(defaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  useEffect(() => {
    const firstError = Object.keys(errors)[0];

    if (firstError) {
      setFocus(firstError as any);
    }
  }, [errors, setFocus]);

  // --------------- routing ---------------
  const handleClose = (donor?: Donor.Donor, andNew?: boolean) => {
    if (andNew) {
      reset(defaultValues);
      return;
    }

    if (context === 'donations') {
      onClose?.(donor);
    } else if (context === 'issue') {
      navigate(PATHS.org.receipts.create.root);
    } else if (context === 'reissue') {
      navigate(PATHS.org.receipts.reissue.root);
    } else if (context === 'reissue-batch') {
      navigate(PATHS.org.receipts.reissueBatch.root);
    } else {
      navigate(PATHS.org.donors.root);
    }
  };

  // --------------- actions ---------------
  const handleFormReset = useCallback(() => {
    reset(defaultValues);
    setInConflict(undefined);
  }, [reset, defaultValues]);

  const formatMemberNumbers = (memberNumbers: { [key: string]: any } = {}) =>
    Object.fromEntries(
      Object.entries(memberNumbers)
        .filter(([, value]) => value != null)
        .map(([key, value]) => [Number(key), Number(value)])
    );

  const onSubmit = async (data: DonorSchemaForm, andNew?: boolean) => {
    if (!canEdit) return;
    try {
      let resDonor: Donor.Donor;
      if (!donor) {
        resDonor = await createDonor({
          orgId: org!.id,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          tagIds: data.tags.map((t) => t.id),
          customFields: data.customFields,
          notes: data.notes,
          address: {
            ...data.address,
            country: data.address?.country.value,
          },
          memberNumbers: formatMemberNumbers(data.memberNumbers),
          nonReceiptable: data.nonReceiptable,
          organization: data.organization,
          phone: data.phone,
          type: data.type,
        });

        enqueueSnackbar('Donor created', {
          key: resDonor.id,
          action: (key) => (
            <IconButtonAnimate
              size="small"
              onClick={() =>
                navigate(
                  getPath(PATHS.org.donors.donor.edit, { orgId: org!.id, donorId: key as string })
                )
              }
              sx={{ p: 0.5 }}
            >
              <Iconify icon={'akar-icons:arrow-right'} />
            </IconButtonAnimate>
          ),
        });
      } else {
        resDonor = await updateDonor({
          donorId: donor.id,
          update: {
            orgId: org!.id,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            email: data.email,
            address: {
              ...data.address,
              country: data.address?.country.value,
            },
            tagIds: data.tags.map((t) => t.id),
            memberNumbers: formatMemberNumbers(data.memberNumbers),
            nonReceiptable: data.nonReceiptable,
            organization: data.organization,
            phone: data.phone,
            type: data.type,
            notes: data.notes,
            customFields: data.customFields,
          },
        });

        enqueueSnackbar('Donor Updated');
      }
      handleClose(resDonor, andNew);
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  const handleDelete = async () => {
    if (!canEdit) {
      setError('afterSubmit', { message: 'You lack permissions!' });
      return;
    }
    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing organization!' });
      return;
    }
    if (!donor) {
      setError('afterSubmit', { message: 'Missing donor to delete!' });
      return;
    }
    if (donor.donations.length > 0) {
      setError('afterSubmit', { message: 'Donor has donations!' });
      return;
    }

    setDeleting(true);
    try {
      await deleteDonor({ orgId: org.id, donorId: donor.id });
    } catch (e) {
      setError('afterSubmit', { message: e.message });
    }
    setDeleting(false);
    handleClose();
  };

  const formDisabled = isSubmitting || isDeleting || !canEdit;

  const hasReceiptable =
    !watchedNonReceiptable &&
    !!donor &&
    donor.donations.some((d) => !getDonationReceipt(d) && !d.nonReceiptable);

  const issueReceiptUrl = !!donor
    ? `${getPath(PATHS.org.receipts.create.root, { orgId: org?.id })}?${createSearchParams({ donorId: donor?.id })}`
    : '';

  const content = (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} {...((!donor || !canViewDonations) && { md: 12 })}>
        <Card sx={{ p: 2 }} variant="outlined">
          <Stack spacing={4}>
            <Stack>
              <ToggleButtonGroup
                color="secondary"
                value={watchType}
                exclusive
                onChange={(_, newType) => setValue('type', newType)}
                aria-label="Donor type"
                sx={{ mt: -2, mx: -2 }}
              >
                <ToggleButton sx={{ flex: '1 1 auto' }} fullWidth value="individual">
                  Individual
                </ToggleButton>
                <ToggleButton sx={{ flex: '1 1 auto' }} fullWidth value="business">
                  Business / Organization
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            <Form isBusiness={watchType === 'business'} formDisabled={formDisabled} />

            <AdditionalForm
              formDisabled={formDisabled}
              nonReceiptable={watchedNonReceiptable}
              setSkipEffect={setSkipEffect}
            />
          </Stack>
        </Card>
      </Grid>

      {!!donor && canViewDonations && (
        <Grid item xs={12} md={7}>
          <Stack spacing={1}>
            <Card sx={{ p: 1 }} variant="outlined">
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                <Typography variant="subtitle1" color="text.secondary" mb={0}>
                  Donations
                </Typography>

                <Stack direction="row" spacing={2}>
                  {hasAccess([Organization.Role.editor, Organization.Role.contributor]) &&
                    context === 'donors' && (
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        component={Link}
                        to={getPath(PATHS.org.donors.donor.donation.create, {
                          orgId: org!.id,
                          donorId: donor?.id,
                        })}
                      >
                        New Donation
                      </Button>
                    )}
                  {context === 'donors' &&
                    !!donor &&
                    hasAccess([Organization.Role.editor]) &&
                    hasReceiptable &&
                    donor.donations.length > 0 && (
                      <Button
                        color="info"
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        component={Link}
                        to={issueReceiptUrl}
                      >
                        Issue Receipt
                      </Button>
                    )}
                </Stack>
              </Stack>
              <Divider sx={{ pt: 1 }} />
              <DonationList
                donor={donor}
                context={context}
                nonReceiptable={watchedNonReceiptable}
              />
            </Card>
            {org?.pledgesEnabled && (
              <Card style={{ marginTop: 20 }} sx={{ p: 1 }} variant="outlined">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="subtitle1" color="text.secondary" mb={0}>
                    Pledges
                  </Typography>

                  {hasAccess([Organization.Role.editor, Organization.Role.contributor]) &&
                    context === 'donors' && (
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        component={Link}
                        to={getPath(PATHS.org.donors.donor.pledge.create, {
                          orgId: org!.id,
                          donorId: donor?.id,
                        })}
                      >
                        New Pledge
                      </Button>
                    )}
                </Stack>
                <Divider sx={{ pt: 1 }} />
                <PledgeList donor={donor} />
              </Card>
            )}
          </Stack>
        </Grid>
      )}
    </Grid>
  );

  // TODO: if !donor, display paywall?

  // --------------------------------------------------
  return (
    <Dialog
      open={isUndefined(open) ? true : open}
      title={!donor ? 'Create donor' : 'Donor'}
      onClose={() => handleClose()}
      maxWidth={donor ? 'xl' : 'md'}
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      actions={
        canEdit && (
          <Stack direction="row" justifyContent="space-between" width="100%">
            {!!donor && donor.donations.length === 0 && !hasReceipt ? (
              <>
                <ConfirmDialog
                  open={isConfirmingDelete}
                  onClose={() => setConfirmingDelete(false)}
                  onConfirm={handleDelete}
                  loading={isDeleting}
                />
                <LoadingButton
                  color="error"
                  onClick={() => setConfirmingDelete(true)}
                  loading={isDeleting}
                >
                  Delete donor
                </LoadingButton>
              </>
            ) : (
              <Box />
            )}

            <Stack direction="row" spacing={2}>
              {!donor && context !== 'donations' && (
                <LoadingButton
                  size="large"
                  variant="outlined"
                  loading={isSubmitting}
                  onClick={handleSubmit((d) => onSubmit(d, true))}
                >
                  Create and New
                </LoadingButton>
              )}

              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit((d) => onSubmit(d))}
              >
                {!donor ? 'Create' : 'Save'}
              </LoadingButton>
            </Stack>
          </Stack>
        )
      }
    >
      <Outlet />
      <ConflictDialog onConfirm={handleFormReset} contentText={isInConflict} />
      {content}

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
